/* Global Styling */
body {
  font-family: 'Inter', sans-serif; /* Use Inter font */
  margin: 0;
  padding: 0;
  background-color: #0a192f; /* Dark background */
  color: #ccd6f6; /* Light text */
}

.container {
  display: flex;
  min-height: 100vh; /* Full height */
  width: 100vw; /* Full viewport width */
  max-width: 1200px; /* Set a maximum width for the container */
  margin: 0 auto; /* Center the container horizontally */
  justify-content: center;
  align-items: flex-start;
}

.left-column {
  width: 50%; /* Keep at 50% */
  padding: 2rem;
  background-color: #112240; /* Slightly lighter background */
  color: white;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: flex-start; /* Align children to the top */
  align-items: flex-start; /* Align children to the start */
  position: sticky; /* Make the left column sticky */
  top: 0; /* Stick to the top */
  height: 100vh; /* Full height of the viewport */
}

.right-column {
  width: 50%; /* Set to 50% */
  padding: 2rem;
  background-color: #0a192f; /* Dark background */
  height: auto; /* Full height of the viewport */
  overflow-y: visible; /* Allow vertical scrolling */
  text-align: right;
}

/* Adjust the BerkanInformation class */
.BerkanInformation {
  width: 80%; /* Limit width of the section */
  max-width: 600px; /* Set a maximum width if needed */
  margin: 0 auto; /* Center it */
  padding: 1rem; /* Add some padding */
}

.Name {
  text-align: left;
  width: 100%;
  font-size: 48px;
  margin-bottom: 1rem;
}

/* Typography */
h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 2.1rem;
  color: whitesmoke; /* Highlight color for header */
  margin-bottom: 16px; /* Bottom margin */
}

p {
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.6;
}

/* Align Projects and Experience */
.right-column .projects, 
.right-column .experience {
  text-align: right;
  margin-bottom: 1rem;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center; /* Align everything centered on mobile */
  }

  .left-column, .right-column {
    width: 100%;
  }
}

.AboutMe4 {
  font-size: 33px;
  text-align: right;
  margin-right: 27.888px;
  font-weight: 600;
  color: #f5f5f5;
}

/* About Me Sections */
.AboutMe, .AboutMe2, .AboutMe3 {
  width: 89%;
  color: #ccd6f6a2; /* Color for about me text */
}

/* Experience Padding */
.ExperienceContainer1 {
  padding: 100px 0 300px; /* Adjust padding */
}

/* Quick Description */
.QuickDescription {
  font-size: 18px;
  margin-top: -0.7rem; /* Negative margin to pull it up */
}

.QuickExplanation {
  color: #ccd6f6a2; /* Color for quick explanation */
}

.quick-summary {
  margin: 20vh 0 100px; /* Top and bottom margin */
}

/* Highlighting Text */
.highlight {
  font-weight: bold; /* Optional: Keep the text bold */
  color: #dbe1f6; /* Highlight color */
}

/* Buttons */
.buttons {
  margin-top: 5vh;
  background: none;
  border: none;
  color: #ccd6f6a2; /* Button text color */
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem; /* Add padding for spacing */
  margin-left: 20px; /* Space between the line and the button */
  position: relative;
  transition: color 0.3s ease, transform 0.3s ease; /* Add transform transition */
}

.buttons::before {
  content: "";
  position: absolute;
  left: -40px; /* Move the line to the left */
  top: 50%;
  width: 30px; /* Initial length of the line */
  height: 1px;
  background-color: #ccd6f6a2; /* Initial line color */
  transition: width 0.3s ease, background-color 0.3s ease;
  transform: translateY(-50%);
}

.buttons:hover::before {
  width: 50px; /* Extended length of the line on hover */
  background-color: #64ffda; /* Color change on hover */
}

.buttons:hover {
  color: #64ffda; /* Text color change on hover */
  transform: translateX(15px); /* Slide text to the right */
}

.buttons:focus {
  outline: none;
}

/* Social Icons */
.social-icons {
  display: flex;
  gap: 5rem;
  margin: 4rem 0; /* Top and bottom margin */
  align-items: center;
  margin-left: 3rem; /* Left margin */
}

.social-icons a {
  color: #ccd6f6;
  font-size: 24px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #64ffda; /* Color on hover */
}

/* Contact Section */
.ContactContainer {
  margin-top: 15rem;
  text-align: center;
}

.Contact {
  padding: 2rem 1rem;
}

.contact-description {
  color: #b0b3b8; /* Color for the description text */
  margin-bottom: 20px;
  font-size: 1rem;
  text-align: right;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.ContactMe {
  text-align: right;
}

/* Input Fields */
.input-field,
.message-field {
  padding: 12px;
  margin-bottom: 16px;
  border: none;
  border-radius: 4px;
  background-color: #2d2f3a; /* Card background */
  color: #fff; /* Input text color */
  font-size: 1rem;
  text-align: right; /* Right-aligned text */
}

.input-field::placeholder,
.message-field::placeholder {
  color: #b0b3b8; /* Placeholder color */
}

/* Submit Button */
.submit-button {
  padding: 12px;
  border: none;
  border-radius: 4px;
  background-color: #38bdf8; /* Button background */
  color: #1e293b; /* Button text color */
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease;
}

.submit-button:hover {
  background-color: #2bb2e8; /* Darker shade on hover */
}

/* Experience Section */
.Experience {
  padding: 2rem 1rem;
}

.experience-content {
  width: 100%;
  max-width: 600px; /* Ensure it fits within the layout */
  padding: 1vh 0px;
}

.experience-card {
  width: 100%;
  height: auto;
  border-radius: 8px;
  padding: 20px; /* Adjusted padding */
  margin: 10px 0; /* Adjusted margin */
  text-align: right;
  color: #fff;
  text-decoration: none;
  background-color: transparent;
  transition: transform 0.2s ease-in-out;
  border: solid 5px red;
}

.experience-card:hover {
  transform: scale(1.05); /* Scale effect on hover */
}

.experience-title {
  font-size: 1.25rem;
  color: #38bdf8; /* Highlight color */
  margin-bottom: 8px;
}

.experience-details {
  font-size: 0.9rem;
  color: #b0b3b8; /* Text color */
}

.experience-description {
  font-size: 0.95rem;
  margin-bottom: 16px;
}

/* Tech Stack */
.tech-stack span {
  display: inline-block;
  background-color: #38bdf8;
  color: #1e293b;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 8px;
  margin-top: 8px;
}

/* World Clock Section */
.world-clock {
  display: flex; /* Use flexbox for horizontal alignment */
  justify-content: space-around; /* Space out the clocks evenly */
  align-items: center; /* Center align items vertically */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  padding: 2px; /* Add some padding around the container */
}

.clock {
  display: flex; /* Use flexbox for alignment */
  flex-direction: column; /* Align icon and text vertically */
  align-items: center; /* Center align the contents */
  margin: 0 10px; /* Adjust margin for spacing */
  font-size: 0.56rem; /* Smaller font size */
}

.icon {
  font-size: 1.1rem; /* Reduce icon size */
}

.ExactTime {
  font-size: 0.7rem;
 } 

 /* Experience Section */
.ExperienceSection {
  padding: 2rem;
  background-color: #0a192f;
  color: #ccd6f6;
}

.ExperienceGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.ExperienceCard {
  background-color: #0a192f;
  height: 600px;
  padding: 1rem;
  border-radius: 8px;
  transition: transform 0.2s ease-in-out;
}

.ExperienceCard:hover {
  transform: scale(1.025);
  background-color: #0d203da7;
}

.ExperienceImage {
  width: 100%;
  border-radius: 8px;
}

.ExperienceInfo {
  margin-top: 1rem;
}

.experience-title {
  color: #64ffda;
  margin-bottom: 0.5rem;
}

.TechStack {
  margin-top: 0.5rem;
}

.TechTag {
  display: inline-block;
  background-color: rgba(100, 255, 218, 0.1);
  color: #64ffda;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  margin-right: 0.5rem;
  font-size: 0.9rem;
}

.ExperienceDescription {
  font-size: 15px;
}
